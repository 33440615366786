const WheelChairIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6702 12.6C10.324 13.2449 9.83192 13.8 9.23325 14.2212C8.63458 14.6423 7.94583 14.9178 7.22187 15.0257C6.49791 15.1336 5.75876 15.0709 5.06331 14.8426C4.36786 14.6143 3.73534 14.2268 3.21615 13.7108C2.69696 13.1949 2.30545 12.5648 2.07281 11.8708C1.84017 11.1768 1.77284 10.4381 1.87618 9.71345C1.97952 8.98883 2.25067 8.29836 2.66804 7.69705C3.0854 7.09575 3.63744 6.60024 4.28016 6.25"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.65914 1.69969C6.65914 2.12495 6.3144 2.46969 5.88914 2.46969C5.46388 2.46969 5.11914 2.12495 5.11914 1.69969C5.11914 1.27443 5.46388 0.929688 5.88914 0.929688C6.3144 0.929688 6.65914 1.27443 6.65914 1.69969Z"
        fill="white"
        stroke="currentColor"
      />
      <path
        d="M5.84961 3.79004L5.95961 9.56004H10.2996L12.8096 12.6H14.1596"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.93066 5.62988H10.0507"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WheelChairIcon;
