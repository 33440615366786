const LegRoomSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.675 3.15C7.675 3.75061 7.18811 4.2375 6.5875 4.2375C5.98689 4.2375 5.5 3.75061 5.5 3.15C5.5 2.54939 5.98689 2.0625 6.5875 2.0625C7.18811 2.0625 7.675 2.54939 7.675 3.15Z"
        fill="#444444"
        stroke="currentColor"
      />
      <path
        d="M6.875 5.9375L7.44997 12.8875H12.875L15 18.4375H16.25"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 7.8125L10 9.6875H13.125"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.25 15.3125H5L4.375 6.875"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LegRoomSvg;
