const WalkingTours = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.25 11.25V8.8118C6.25 8.77393 6.2714 8.7393 6.30528 8.72236L9.71056 7.01972C9.84354 6.95323 10 7.04993 10 7.19861V12.507C10 12.8857 10.214 13.232 10.5528 13.4014L12.3807 14.3154C12.4582 14.3541 12.5209 14.4168 12.5596 14.4943L14.375 18.125"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M6.25 18.125L9.375 15"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M11.875 7.5L14.375 10"
      stroke="currentColor"
      strokeLinecap="round"
    />
    <path
      d="M11.5625 3.4375C11.5625 4.30044 10.8629 5 10 5C9.13706 5 8.4375 4.30044 8.4375 3.4375C8.4375 2.57456 9.13706 1.875 10 1.875C10.8629 1.875 11.5625 2.57456 11.5625 3.4375Z"
      stroke="currentColor"
    />
  </svg>
);
export default WalkingTours;
