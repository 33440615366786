const RestRoomSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 14L15 16.5L15 18"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        d="M5 14.5L5 18"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        d="M13.5 6.5L12.2392 12.8039C12.1155 13.4227 12.5888 14 13.2198 14H16.8578C17.4626 14 17.929 13.4673 17.849 12.8678L17 6.5"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 6.5L2.5 13C2.5 13.5523 2.94772 14 3.5 14H6.5C7.05228 14 7.5 13.5523 7.5 13V6.5"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="15.25"
        cy="3.5"
        r="1.875"
        stroke="currentColor"
        strokeWidth="1"
      />
      <circle cx="5" cy="3.5" r="1.875" stroke="currentColor" strokeWidth="1" />
      <path
        d="M10 1.5V18.5"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RestRoomSvg;
