const ValueForMoneyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.1549 13.8683C16.6298 10.7093 14.9953 8.35689 13.5743 6.9752C13.1608 6.57313 12.9541 6.3721 12.4974 6.18606C12.0407 6 11.6481 6 10.863 6H9.13851C8.3534 6 7.96083 6 7.50413 6.18606C7.04743 6.3721 6.84069 6.57313 6.42719 6.9752C5.00622 8.35689 3.37166 10.7093 2.8466 13.8683C2.45593 16.2187 4.62419 17.9999 7.04741 17.9999H12.9541C15.3773 17.9999 17.5456 16.2187 17.1549 13.8683Z"
        stroke="#444444"
        strokeWidth="0.959995"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.20529 3.95429C6.04025 3.71406 5.80102 3.38799 6.29519 3.31363C6.80313 3.2372 7.33056 3.5849 7.84683 3.57776C8.31388 3.5713 8.55183 3.36415 8.80711 3.06838C9.0759 2.75693 9.49214 2 9.99998 2C10.5078 2 10.9241 2.75693 11.1929 3.06838C11.4481 3.36415 11.6861 3.5713 12.1531 3.57776C12.6694 3.5849 13.1968 3.2372 13.7048 3.31363C14.1989 3.38799 13.9597 3.71406 13.7947 3.95429L13.0484 5.0405C12.7292 5.50515 12.5696 5.73748 12.2355 5.86873C11.9015 5.99998 11.4698 5.99998 10.6065 5.99998H9.39342C8.53015 5.99998 8.09847 5.99998 7.76444 5.86873C7.43041 5.73748 7.27079 5.50515 6.95156 5.0405L6.20529 3.95429Z"
        stroke="#444444"
        strokeWidth="0.959995"
        strokeLinejoin="round"
      />
      <path
        d="M11.3559 10.7651C11.1757 10.1 10.2587 9.49982 9.15799 9.94882C8.05724 10.3977 7.88239 11.8423 9.54741 11.9958C10.2999 12.0652 10.7906 11.9153 11.2397 12.3392C11.689 12.7631 11.7724 13.9419 10.6241 14.2596C9.47574 14.5772 8.33866 14.0809 8.21484 13.376M9.86841 9.16016V9.79382M9.86841 14.3573V14.9935"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ValueForMoneyIcon;
