const DiscoBallSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1916_16321"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="3"
        y="4"
        width="14"
        height="14"
      >
        <path
          d="M16.25 10.8327C16.25 13.9968 13.4518 16.5618 10 16.5618C6.54822 16.5618 3.75 13.9968 3.75 10.8327C3.75 7.66855 6.54822 5.10352 10 5.10352C13.4518 5.10352 16.25 7.66855 16.25 10.8327Z"
          fill="currentColor"
          stroke="currentColor"
        />
      </mask>
      <g mask="url(#mask0_1916_16321)">
        <path
          d="M4.79297 7.1875L5.71126 7.37116C8.54324 7.93756 11.4594 7.93756 14.2913 7.37116L15.2096 7.1875"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M4.79297 13.957L5.71126 14.1407C8.54324 14.7071 11.4594 14.7071 14.2913 14.1407L15.2096 13.957"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M3.75 10.3125V10.3125C7.80694 11.6648 12.1931 11.6648 16.25 10.3125V10.3125"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M10.5195 17.082L10.9348 16.5552C13.7009 13.046 13.5252 8.05154 10.5195 4.74525V4.74525"
          stroke="currentColor"
          strokeLinecap="round"
        />
        <path
          d="M9.48047 16.9199L9.06522 16.3931C6.29914 12.8839 6.47475 7.88942 9.48047 4.58313V4.58313"
          stroke="currentColor"
          strokeLinecap="round"
        />
      </g>
      <path
        d="M16.25 10.834C16.25 14.2858 13.4518 17.084 10 17.084C6.54822 17.084 3.75 14.2858 3.75 10.834C3.75 7.3822 6.54822 4.58398 10 4.58398C13.4518 4.58398 16.25 7.3822 16.25 10.834Z"
        stroke="currentColor"
      />
      <path
        d="M10 4.58333V2.5"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        d="M14.8048 0.993486C14.8688 0.852733 15.0687 0.852733 15.1327 0.993486L15.5102 1.82403C15.5438 1.89794 15.603 1.95717 15.6769 1.99076L16.5075 2.36828C16.6482 2.43226 16.6482 2.63219 16.5075 2.69617L15.6769 3.07369C15.603 3.10728 15.5438 3.16652 15.5102 3.24042L15.1327 4.07097C15.0687 4.21172 14.8688 4.21172 14.8048 4.07097L14.4273 3.24042C14.3937 3.16652 14.3345 3.10728 14.2606 3.07369L13.43 2.69617C13.2893 2.63219 13.2893 2.43226 13.43 2.36828L14.2606 1.99076C14.3345 1.95717 14.3937 1.89794 14.4273 1.82403L14.8048 0.993486Z"
        fill="currentColor"
      />
      <path
        d="M17.6659 3.9493C17.7376 3.87154 17.8675 3.91434 17.8789 4.01946L17.9404 4.58573C17.9466 4.64297 17.9741 4.69579 18.0174 4.73371L18.446 5.10888C18.5255 5.17853 18.4861 5.30946 18.3813 5.32357L17.7826 5.40419C17.7296 5.41133 17.6807 5.43678 17.6444 5.47613L17.235 5.92031C17.1633 5.99807 17.0334 5.95527 17.022 5.85014L16.9605 5.28388C16.9543 5.22664 16.9268 5.17382 16.8835 5.1359L16.4549 4.76073C16.3753 4.69108 16.4148 4.56015 16.5196 4.54603L17.1183 4.46542C17.1713 4.45827 17.2202 4.43283 17.2565 4.39348L17.6659 3.9493Z"
        fill="currentColor"
      />
      <path
        d="M3.0129 16.399C3.05664 16.3027 3.19336 16.3027 3.2371 16.399L3.49525 16.9669C3.51823 17.0174 3.55873 17.0579 3.60927 17.0809L4.1772 17.3391C4.27345 17.3828 4.27345 17.5195 4.1772 17.5633L3.60927 17.8214C3.55873 17.8444 3.51823 17.8849 3.49526 17.9354L3.2371 18.5034C3.19336 18.5996 3.05664 18.5996 3.0129 18.5034L2.75474 17.9354C2.73177 17.8849 2.69127 17.8444 2.64073 17.8214L2.0728 17.5633C1.97655 17.5195 1.97655 17.3828 2.0728 17.3391L2.64073 17.0809C2.69127 17.0579 2.73177 17.0174 2.75475 16.9669L3.0129 16.399Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DiscoBallSvg;
