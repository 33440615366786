const SeatIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1730_29839)">
        <path
          d="M4.16667 9.16699C4.60869 9.16699 5.03262 9.34259 5.34518 9.65515C5.65774 9.96771 5.83333 10.3916 5.83333 10.8337V12.5003H14.1667V10.8337C14.1667 10.3916 14.3423 9.96771 14.6548 9.65515C14.9674 9.34259 15.3913 9.16699 15.8333 9.16699C16.2754 9.16699 16.6993 9.34259 17.0118 9.65515C17.3244 9.96771 17.5 10.3916 17.5 10.8337V14.167C17.5 14.609 17.3244 15.0329 17.0118 15.3455C16.6993 15.6581 16.2754 15.8337 15.8333 15.8337H4.16667C3.72464 15.8337 3.30072 15.6581 2.98816 15.3455C2.67559 15.0329 2.5 14.609 2.5 14.167V10.8337C2.5 10.3916 2.67559 9.96771 2.98816 9.65515C3.30072 9.34259 3.72464 9.16699 4.16667 9.16699Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16602 9.16667V5C4.16602 4.33696 4.42941 3.70107 4.89825 3.23223C5.36709 2.76339 6.00297 2.5 6.66602 2.5H13.3327C13.9957 2.5 14.6316 2.76339 15.1005 3.23223C15.5693 3.70107 15.8327 4.33696 15.8327 5V9.16667"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 15.833V17.4997"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 15.833V17.4997"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1730_29839">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SeatIcon;
