const Wineries = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.24438 1.92279C6.24758 1.89554 6.27068 1.875 6.29812 1.875H13.7019C13.7293 1.875 13.7524 1.89554 13.7556 1.92279L14.4158 7.53425C14.7268 10.1775 12.6615 12.5 10 12.5V12.5C7.33851 12.5 5.27323 10.1775 5.58421 7.53425L6.24438 1.92279Z"
      stroke="currentColor"
    />
    <path d="M10 12.5V17.5" stroke="currentColor" strokeLinecap="round" />
    <path d="M7.5 18.125H12.5" stroke="currentColor" strokeLinecap="round" />
    <path
      d="M9.30349 6.97776L9.60356 6.57781L9.30349 6.97776ZM9.99963 7.50005L10.3119 7.10956L10.3059 7.10474L10.2997 7.10011L9.99963 7.50005ZM10.3612 7.78916L10.0489 8.17965V8.17965L10.3612 7.78916ZM12.9619 8.1104L12.7541 7.65566L12.9619 8.1104ZM13.9581 8.20479L14.4128 7.99691L13.9971 7.08744L13.5423 7.29532L13.9581 8.20479ZM5.125 8.12512V8.62512H6.125V8.12512H5.125ZM9.00342 7.3777L9.69956 7.9L10.2997 7.10011L9.60356 6.57781L9.00342 7.3777ZM9.68736 7.89054L10.0489 8.17965L10.6734 7.39867L10.3119 7.10956L9.68736 7.89054ZM13.1698 8.56514L13.9581 8.20479L13.5423 7.29532L12.7541 7.65566L13.1698 8.56514ZM10.0489 8.17965C10.9331 8.88678 12.1401 9.03586 13.1698 8.56514L12.7541 7.65566C12.0676 7.96948 11.2629 7.87009 10.6734 7.39867L10.0489 8.17965ZM6.125 8.12512C6.125 7.59029 6.26758 7.29728 6.42436 7.13245C6.58421 6.96438 6.82217 6.86299 7.13841 6.83674C7.79803 6.78198 8.59848 7.07388 9.00342 7.3777L9.60356 6.57781C9.02933 6.14697 7.99053 5.76256 7.05569 5.84016C6.57471 5.88009 6.07664 6.04703 5.69977 6.44326C5.31984 6.84272 5.125 7.40996 5.125 8.12512H6.125Z"
      fill="currentColor"
    />
  </svg>
);
export default Wineries;
