const ArtBoardSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.922 5.47245C6.542 5.47245 6.174 5.34845 5.87 5.11245C5.114 4.52845 4.974 3.44045 5.558 2.68445C6.142 1.92845 7.23 1.78845 7.986 2.37245C8.35399 2.65645 8.586 3.06445 8.646 3.52045C8.706 3.98045 8.582 4.43245 8.298 4.80045C8.014 5.16845 7.60599 5.40045 7.14999 5.46045C7.07399 5.46845 6.998 5.47645 6.926 5.47645L6.922 5.47245ZM6.926 3.01245C6.706 3.01245 6.49 3.10845 6.346 3.29645C6.102 3.61645 6.158 4.07645 6.478 4.32045C6.634 4.44045 6.82599 4.49245 7.01799 4.46845C7.20999 4.44445 7.382 4.34445 7.502 4.18845C7.622 4.03245 7.67399 3.84045 7.64999 3.64845C7.62599 3.45645 7.526 3.28445 7.37 3.16445C7.238 3.06045 7.082 3.01245 6.926 3.01245Z"
        fill="#444545"
      />
      <path
        d="M8.67551 15.9171C7.76351 15.9171 6.84351 15.6291 6.06751 15.0291C5.16351 14.3331 4.58351 13.3251 4.43951 12.1931C4.29551 11.0611 4.59551 9.94109 5.29551 9.03709C5.59951 8.64109 5.97151 8.30509 6.39151 8.03709C6.62351 7.88909 6.93151 7.95709 7.07951 8.19309C7.22751 8.42509 7.15951 8.73709 6.92351 8.88509C6.60351 9.08909 6.31951 9.34509 6.08751 9.64909C5.55551 10.3411 5.31951 11.2011 5.43151 12.0691C5.54351 12.9371 5.98751 13.7091 6.67951 14.2411C8.10751 15.3451 10.1675 15.0771 11.2715 13.6491C11.5355 13.3091 11.7235 12.9251 11.8395 12.5131C11.9115 12.2451 12.1875 12.0891 12.4555 12.1611C12.7235 12.2331 12.8795 12.5091 12.8075 12.7771C12.6595 13.3131 12.4115 13.8131 12.0675 14.2571C11.2275 15.3451 9.95951 15.9171 8.67951 15.9171H8.67551Z"
        fill="#444545"
      />
      <path
        d="M13.8521 12.9604L11.3001 9.91641C11.2801 9.89241 11.2481 9.88441 11.2241 9.88841L8.81614 10.4204C8.33614 10.5284 7.85614 10.2204 7.74814 9.74041L7.00414 6.38041C6.94414 6.11241 7.11614 5.84441 7.38414 5.78441C7.65214 5.72441 7.92014 5.89641 7.98014 6.16441L8.70414 9.42441L11.0081 8.91241C11.4041 8.82441 11.8081 8.96441 12.0681 9.27241L14.0281 11.6084L14.8041 11.0564C15.0281 10.8964 15.3401 10.9484 15.5001 11.1764C15.6601 11.4004 15.6081 11.7124 15.3801 11.8724L13.8521 12.9564V12.9604Z"
        fill="#444545"
      />
      <path
        d="M7.77534 8.06042C7.54734 8.06042 7.33934 7.90042 7.28734 7.66842C7.22734 7.40042 7.39934 7.13242 7.66734 7.07242L10.2833 6.49242C10.5513 6.43242 10.8193 6.60442 10.8793 6.87242C10.9393 7.14042 10.7673 7.40842 10.4993 7.46842L7.88334 8.04842C7.84734 8.05642 7.81134 8.06042 7.77534 8.06042Z"
        fill="#444545"
      />
      <path
        d="M17.0987 17.3962H2.83072C2.55472 17.3962 2.32272 17.1882 2.29072 16.9122C2.25872 16.6362 2.44272 16.3842 2.71072 16.3202L17.0987 13.1282C17.3667 13.0682 17.6347 13.2402 17.6947 13.5082C17.7547 13.7762 17.5827 14.0442 17.3147 14.1042L6.99472 16.3922H17.0987C17.3747 16.3922 17.5987 16.6162 17.5987 16.8922C17.5987 17.1682 17.3747 17.3922 17.0987 17.3922V17.3962Z"
        fill="#444545"
      />
    </svg>
  );
};

export default ArtBoardSvg;
