const BinocularIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.5 9.375H7.1875" stroke="currentColor" />
      <path
        d="M11.875 11.875H7.5M6.25 8.125L6.82942 6.38675C6.85664 6.30509 6.93307 6.25 7.01915 6.25H12.9808C13.0669 6.25 13.1434 6.30509 13.1706 6.38675L13.75 8.125"
        stroke="currentColor"
      />
      <path
        d="M18.75 10.8478C18.75 12.697 17.251 14.196 15.4018 14.196C13.5526 14.196 12.0536 12.697 12.0536 10.8478C12.0536 8.99862 13.5526 7.49958 15.4018 7.49958C17.251 7.49958 18.75 8.99862 18.75 10.8478Z"
        stroke="currentColor"
      />
      <path
        d="M7.94643 10.6246C7.94643 12.4737 6.44738 13.9728 4.59821 13.9728C2.74905 13.9728 1.25 12.4737 1.25 10.6246C1.25 8.7754 2.74905 7.27635 4.59821 7.27635C6.44738 7.27635 7.94643 8.7754 7.94643 10.6246Z"
        stroke="currentColor"
      />
      <path
        d="M5 12.5C3.96447 12.5 3.125 11.6605 3.125 10.625"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        d="M15.625 12.5C14.5895 12.5 13.75 11.6605 13.75 10.625"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default BinocularIcon;
