const DrinkSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.24438 1.92279C6.24758 1.89554 6.27068 1.875 6.29812 1.875H13.7019C13.7293 1.875 13.7524 1.89554 13.7556 1.92279L14.4158 7.53425C14.7268 10.1775 12.6615 12.5 10 12.5V12.5C7.33851 12.5 5.27323 10.1775 5.58421 7.53425L6.24438 1.92279Z"
        stroke="currentColor"
      />
      <path d="M10 12.5V17.5" stroke="currentColor" strokeLinecap="round" />
      <path d="M7.5 18.125H12.5" stroke="currentColor" strokeLinecap="round" />
      <path
        d="M13.7502 7.75005L12.9619 8.1104C12.1039 8.50267 11.098 8.37843 10.3612 7.78916L9.99963 7.50005L9.30349 6.97776C8.32431 6.2431 5.625 5.62514 5.625 8.12512"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default DrinkSvg;
