const SwivelSeatsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_4068_16724)">
        <path
          d="M5.41189 10.5146C5.78635 10.5146 6.14547 10.6422 6.41025 10.8694C6.67503 11.0965 6.82378 11.4045 6.82378 11.7257V12.9368H13.8832V11.7257C13.8832 11.4045 14.032 11.0965 14.2968 10.8694C14.5616 10.6422 14.9207 10.5146 15.2951 10.5146C15.6696 10.5146 16.0287 10.6422 16.2935 10.8694C16.5583 11.0965 16.707 11.4045 16.707 11.7257V14.1479C16.707 14.4691 16.5583 14.7772 16.2935 15.0043C16.0287 15.2314 15.6696 15.359 15.2951 15.359H5.41189C5.03744 15.359 4.67831 15.2314 4.41353 15.0043C4.14875 14.7772 4 14.4691 4 14.1479V11.7257C4 11.4045 4.14875 11.0965 4.41353 10.8694C4.67831 10.6422 5.03744 10.5146 5.41189 10.5146Z"
          stroke="#444444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.21484 10.5143V8.93625M15.4908 10.5143V4.20198C15.4908 3.61798 15.2588 3.0579 14.8458 2.64495C14.4329 2.23199 13.8728 2 13.2888 2H7.41683C6.83283 2 6.27274 2.23199 5.85979 2.64495C5.44684 3.0579 5.21484 3.61798 5.21484 4.20198V5.78007"
          stroke="#444444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.00098 3.53601C2.46913 3.81218 1.57987 4.25954 1.74459 5.36376C1.78097 5.60758 1.938 5.81642 2.2156 6.05488C2.96162 6.69571 3.79837 7.00121 5.49809 7.31679C7.23584 7.58547 9.38472 7.38755 9.38472 7.38755M9.38472 7.38755L9.38783 7.38713L9.38692 7.3844L9.38413 7.38537L9.38472 7.38755Z"
          stroke="#444444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.58008 5.92578L10.0801 7.42578L8.58008 8.92578"
          stroke="#444444"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M10.5 15.5V18" stroke="#444444" strokeLinecap="round" />
        <path d="M12.5 18H8.5" stroke="#444444" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_4068_16724">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SwivelSeatsIcon;
