const CloseToExitIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path d="M14.6934 13.0811L17.6934 10.0811L14.6934 7.08105" fill="white" />
      <path
        d="M14.6934 13.0811L17.6934 10.0811L14.6934 7.08105"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.127 10.0811H10.127"
        stroke="#444444"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 5.00049V3.55495C15 2.89573 14.4656 2.36133 13.8064 2.36133H5.19362C4.5344 2.36133 4 2.89573 4 3.55495V16.4461C4 17.1053 4.5344 17.6397 5.19362 17.6397H13.8064C14.4656 17.6397 15 17.1053 15 16.4461V15.0005"
        stroke="#444444"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CloseToExitIcon;
