const AccessibilityIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3365 15.75C12.9037 16.5561 12.2887 17.2501 11.5403 17.7765C10.792 18.3029 9.93107 18.6472 9.02612 18.7821C8.12117 18.917 7.19723 18.8386 6.32792 18.5532C5.45861 18.2679 4.66796 17.7835 4.01897 17.1385C3.36998 16.4936 2.88059 15.706 2.58979 14.8385C2.29899 13.971 2.21483 13.0476 2.344 12.1418C2.47318 11.236 2.81212 10.3729 3.33383 9.62132C3.85553 8.86969 4.54558 8.2503 5.34898 7.8125"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.44844 2.12461C8.44844 2.72522 7.96155 3.21211 7.36094 3.21211C6.76033 3.21211 6.27344 2.72522 6.27344 2.12461C6.27344 1.524 6.76033 1.03711 7.36094 1.03711C7.96155 1.03711 8.44844 1.524 8.44844 2.12461Z"
        fill="#666666"
        stroke="#666666"
      />
      <path
        d="M7.3125 4.73633L7.45 11.9488H12.875L16.0125 15.7488H17.7"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.41211 7.03516H12.5621"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AccessibilityIcon;
