const SeatIcon2 = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.63816 2.89532C4.69314 2.10944 5.3467 1.5 6.1345 1.5H9.89794C10.6878 1.5 11.3423 2.1125 11.3946 2.90061L11.9657 11.5H4.0362L4.63816 2.89532Z"
        stroke="currentColor"
      />
      <path
        d="M12.5 12C12.5 12.8284 11.8284 13.5 11 13.5L5 13.5C4.17157 13.5 3.5 12.8284 3.5 12L3.5 11.5L12.5 11.5L12.5 12Z"
        stroke="currentColor"
      />
      <path
        d="M5.5 15C5.5 15.2761 5.72386 15.5 6 15.5C6.27614 15.5 6.5 15.2761 6.5 15H5.5ZM5.5 13V15H6.5V13H5.5Z"
        fill="currentColor"
      />
      <path
        d="M9.5 15C9.5 15.2761 9.72386 15.5 10 15.5C10.2761 15.5 10.5 15.2761 10.5 15H9.5ZM9.5 13V15H10.5V13H9.5Z"
        fill="currentColor"
      />
      <line
        x1="2.5"
        y1="9.5"
        x2="3.5"
        y2="9.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="12.5"
        y1="9.5"
        x2="13.5"
        y2="9.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line x1="3.5" y1="12" x2="3.5" y2="9" stroke="currentColor" />
      <line x1="12.5" y1="12" x2="12.5" y2="9" stroke="currentColor" />
    </svg>
  );
};

export default SeatIcon2;
