const CouchSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 7.5V7.08333C16.25 4.55203 14.198 2.5 11.6667 2.5H8.33333C5.80203 2.5 3.75 4.55203 3.75 7.08333V7.5"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
      />
      <path
        d="M5 13.125H10H15"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 16.25V18.75M16.25 16.25V18.75"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 16.25H18.125V8.75C18.125 8.05964 17.5654 7.5 16.875 7.5H16.25C15.5596 7.5 15 8.05964 15 8.75V13.125"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.875 16.25H1.875V8.75C1.875 8.05964 2.43464 7.5 3.125 7.5H3.75C4.44036 7.5 5 8.05964 5 8.75V13.125"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CouchSvg;
