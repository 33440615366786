const MovableSeatsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5 3.81333C5 2.81186 5.81186 2 6.81333 2H13.1867C14.1881 2 15 2.81186 15 3.81333V11H5V3.81333Z"
        stroke="#444444"
      />
      <path
        d="M15.668 12.8133C15.668 11.8119 14.8561 11 13.8546 11L6.14797 11C5.14649 11 4.33464 11.8119 4.33464 12.8133L4.33464 13.52C4.33464 14.0723 4.78235 14.52 5.33464 14.52L14.668 14.52C15.2203 14.52 15.668 14.0723 15.668 13.52L15.668 12.8133Z"
        stroke="#444444"
      />
      <path
        d="M6.23438 18C6.23438 18.2761 6.45823 18.5 6.73438 18.5C7.01052 18.5 7.23438 18.2761 7.23438 18H6.23438ZM6.23438 14.5V18H7.23438V14.5L6.23438 14.5Z"
        fill="#444444"
      />
      <path
        d="M12.7676 18C12.7676 18.2761 12.9914 18.5 13.2676 18.5C13.5437 18.5 13.7676 18.2761 13.7676 18H12.7676ZM12.7676 15V18H13.7676V15L12.7676 15Z"
        fill="#444444"
      />
      <path d="M5 9H3" stroke="#444444" strokeLinecap="round" />
      <path d="M17 9H15" stroke="#444444" strokeLinecap="round" />
    </svg>
  );
};

export default MovableSeatsIcon;
